const CHUTE_STATUSES = {
  ERROR: "error",
  WARNING: "warning",
  SUCCESS: "success",
  OFF: "off",
  ACTIVATION_NEEDED: "activation-needed",
};

document.addEventListener("turbo:load", () => {
  if (!document.querySelector("table.map")) return;

  const chutes = document.querySelectorAll("td.chute");

  setMutationObserverForClass(chutes, hideParcelIcon);

  if (document.querySelector(".map-aio")) {
    const extras = document.querySelectorAll(".chute-extras");
    setMutationObserverForClass(extras, changeChuteIconColor);
  }
});

// TODO - add to FE Kit
const setMutationObserverForClass = (elements, functions, parameter) => {
  const config = { attributeFilter: ["class"] };
  elements.forEach(el => {
    const composeObserver = new MutationObserver(mutations => {
      mutations.forEach(() => {
        parameter ? functions(el, parameter) : functions(el);
      });
    });

    composeObserver.observe(el, config);
  });
};

const hideParcelIcon = td => {
  if (td.classList.contains(CHUTE_STATUSES.SUCCESS)) return;
  const parcelIcon = td.querySelector(".icon-parcel");

  while (parcelIcon.firstChild) {
    parcelIcon.removeChild(parcelIcon.firstChild);
  }
};

const changeChuteIconColor = extraWithChange => {
  const chute = extraWithChange.closest("td.chute");
  const extras = chute.querySelectorAll(".chute-extras");
  const switchClasses = (addStatus, ...removeStatus) => {
    chute.classList.add(addStatus);
    chute.classList.remove(...removeStatus);
  };
  // Check status class for first and second branch inside chutes-wrapper in AIO map
  const checkBranchesStatus = (extras, className) => {
    return (
      extras[0].classList.contains(className) ||
      extras[1].classList.contains(className)
    );
  };

  if (checkBranchesStatus(extras, CHUTE_STATUSES.ERROR)) {
    switchClasses(
      CHUTE_STATUSES.ERROR,
      CHUTE_STATUSES.SUCCESS,
      CHUTE_STATUSES.WARNING,
      CHUTE_STATUSES.OFF
    );
  } else if (checkBranchesStatus(extras, CHUTE_STATUSES.WARNING)) {
    switchClasses(
      CHUTE_STATUSES.WARNING,
      CHUTE_STATUSES.SUCCESS,
      CHUTE_STATUSES.OFF
    );
  } else {
    switchClasses(
      CHUTE_STATUSES.SUCCESS,
      CHUTE_STATUSES.ERROR,
      CHUTE_STATUSES.WARNING,
      CHUTE_STATUSES.OFF
    );
  }
};
