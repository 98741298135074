import { getChoices, addChoices, choicesOptions } from "./choices";
import Choices from "choices.js";
import { Api } from "./utils/api";

const DISPLAY_NONE = "d-none";
const NOT_AGGREGATED = "not_aggregated";
const ROUTING_TYPES = {
  AGGREGATED_LINE: "aggregated_line",
  LINE: "line",
  AGGREGATED_RETURN_CLIENT: "aggregated_return_client",
  RETURN_CLIENT: "return_client",
  AGGREGATED_EXPEDITION: "aggregated_expedition",
  EXPEDITION: "expedition",
  AGGREGATED_MICRODEPOT_EXPEDITION: "aggregated_microdepot_expedition",
  MICRODEPOT_EXPEDITION: "microdepot_expedition",
  AGGREGATED_PICKUP_POINT_ROUTE: "aggregated_pickup_point_route",
  PICKUP_POINT_ROUTE: "pickup_point_route",
  AGGREGATED_EXTERNAL_CARRIER_ROUTE: "aggregated_external_carrier_route",
  EXTERNAL_CARRIER_ROUTE: "external_carrier_route",
  AGGREGATED_HD_CZ_ROUTE: "aggregated_hd_cz_route",
  HD_CZ_ROUTE: "hd_cz_route",
  AGGREGATED_SYSTEM: "aggregated_system",
};

document.addEventListener("turbo:load", () => {
  initChuteConfigurationModal();
});

const initChuteConfigurationModal = () => {
  const modal = document.querySelector("#chute-configuration-modal");
  if (!modal) return;

  const typeSelectEl = document.querySelector(
    "#chute_config_chute_routing_attributes_routing_type"
  );
  const typeChoices = getChoices(typeSelectEl);
  const header = modal.querySelector("h5");
  const form = modal.querySelector("form");
  const destroyBtn = modal.querySelector(".destroy-config");
  const chuteSelectEl = document.querySelector("#chute_config_chute_id");
  const elements = queryModalChoices(modal);
  const routingTypeSwitch = document.querySelector(
    "#routing-type-group-switch"
  );

  destroyChoices(chuteSelectEl);
  for (const [key, value] of Object.entries(elements)) {
    destroyChoices(value.select);
  }

  modal.addEventListener("show.bs.modal", event => {
    const button = event.relatedTarget;
    const chuteId = button.getAttribute("data-bs-chute-id");
    const chuteConfigId = button.getAttribute("data-bs-chute-config-id");
    const chuteCode = button.getAttribute("data-bs-chute-code");
    const headerText = header.textContent;
    const action = form.getAttribute("action");
    const destroyAction = destroyBtn.getAttribute("href");

    modal.setAttribute("data-chute-id", chuteId);
    modal.setAttribute("data-chute-code", chuteCode);
    modal.setAttribute("data-original-destroy-action", destroyAction);
    modal.setAttribute("data-original-header", headerText);
    modal.setAttribute("data-original-action", action);
    form.setAttribute(
      "action",
      action.replace(encodeURIComponent("%%chute_id%%"), chuteId)
    );
    header.textContent = headerText.replace("%%chute_code%%", chuteCode);
    removeChuteFromSelect(chuteSelectEl, chuteId);

    if (!chuteConfigId) {
      toggleGroupButtons(routingTypeSwitch, NOT_AGGREGATED);
      setGroupedTypeChoices(typeChoices, routingTypeSwitch, NOT_AGGREGATED);
      destroyBtn.classList.add(DISPLAY_NONE);
    } else {
      const destroyActionWithId = destroyAction.replace(
        encodeURIComponent("%%chute_config_id%%"),
        chuteConfigId
      );

      fetchChuteRoutingConfig(modal, chuteConfigId, typeChoices);
      destroyBtn.setAttribute("href", destroyActionWithId);
      destroyBtn.classList.remove(DISPLAY_NONE);
    }

    typeChoices.setChoiceByValue("overflow");
  });

  modal.addEventListener("hidden.bs.modal", () => {
    const chuteId = modal.getAttribute("data-chute-id");
    const chuteCode = modal.getAttribute("data-chute-code");

    header.textContent = modal.getAttribute("data-original-header");
    form.action = modal.getAttribute("data-original-action");
    destroyBtn.setAttribute(
      "href",
      modal.getAttribute("data-original-destroy-action")
    );

    typeChoices.removeActiveItems();
    typeChoices.enable();

    toggleGroupButtons(routingTypeSwitch, NOT_AGGREGATED);
    setGroupedTypeChoices(typeChoices, routingTypeSwitch, NOT_AGGREGATED);
    destroyChoices(chuteSelectEl);
    addChuteToSelect(chuteSelectEl, chuteId, chuteCode);
    hideSelects(elements);
  });

  typeSelectEl.addEventListener("change", event => {
    const routingType = event.detail.value;
    const group = currentRoutingGroup();

    hideSelects(elements);
    initializeRoutingTypeChoices(group, elements, routingType);
  });

  chuteSelectEl.addEventListener("change", () => {
    const value = getChoices(chuteSelectEl).getValue(true);
    const ppChoice = getChoices(elements.pickupPoint.select);
    const routingChoice = getChoices(elements.routings.select);
    const systemChoice = getChoices(elements.system.select);
    const microdepotChoice = getChoices(elements.microdepots.select);
    const selects = [
      typeChoices,
      ppChoice,
      routingChoice,
      systemChoice,
      microdepotChoice,
    ];
    const action = !value ? "enable" : "disable";

    selects.forEach(select => select?.[action]());
  });

  document
    .querySelectorAll("#routing-type-group-switch button")
    .forEach(btn => {
      btn.addEventListener("click", () => {
        toggleGroupButtons(routingTypeSwitch, btn.dataset.type);
        setGroupedTypeChoices(typeChoices, routingTypeSwitch, btn.dataset.type);
        hideSelects(elements);
      });
    });
};

const destroyChoices = element => {
  if (!element) return;
  const choices = getChoices(element);
  if (!choices) return;

  choices.enable();
  choices.destroy();

  window.choices.splice((window.choices.indexOf(choices), 1));

  delete window.choicesMap[element.dataset.choicesID];
  delete element.dataset.choicesID;
};

const initRoutingTypeGroupSwitch = (data, typeChoices) => {
  const routingType = data.routing_type;
  const routingGroupSwitch = document.querySelector(
    "#routing-type-group-switch"
  );
  let group = JSON.parse(routingGroupSwitch.dataset.typeToGroup)[routingType];

  if (group === "aggregated" && data.ptl_mode !== "none") {
    group = data.ptl_mode;
  }

  toggleGroupButtons(routingGroupSwitch, group);
  setGroupedTypeChoices(typeChoices, routingGroupSwitch, group);
  typeChoices.setChoiceByValue(routingType);

  return group;
};

const currentRoutingGroup = () => {
  return document.querySelector("#routing-type-group-switch").dataset
    .currentGroup;
};

const toggleGroupButtons = (routingGroupSwitch, group) => {
  const buttons = routingGroupSwitch.querySelectorAll("button");

  buttons.forEach(({ classList, dataset }) => {
    const { type } = dataset;

    classList.toggle("btn-primary", type === group);
    classList.toggle("btn-outline-secondary", type !== group);
  });
};

const setGroupedTypeChoices = (typeChoices, groupSwitch, selectedGroup) => {
  const selectedChoices = JSON.parse(groupSwitch.dataset.choices)[
    selectedGroup
  ];
  const input = document.querySelector("#chute_config_ptl_mode");

  typeChoices.setChoices(selectedChoices, "value", "label", true);

  selectedGroup === "on_ground" || selectedGroup === "at_chute"
    ? (input.value = selectedGroup)
    : (input.value = "none");

  groupSwitch.dataset.currentGroup = selectedGroup;
};

const initChuteConfig = (data, typeChoices, modal) => {
  const group = initRoutingTypeGroupSwitch(data, typeChoices);
  const elements = queryModalChoices(modal);
  const routingType = data.routing_type;
  const routes = () => {
    const ids = [
      "pickup_point_ids",
      "depot_system_routing_ids",
      "external_carrier_route_ids",
      "microdepot_ids",
      "pickup_point_route_ids",
      "hd_cz_route_ids",
      "routing_ids",
      "system_routings",
    ];

    for (const id of ids) {
      if (data[id].length) {
        console.log(data[id]);
        return data[id];
      }
    }
  };

  initializeRoutingTypeChoices(group, elements, routingType, routes());
};

const queryModalChoices = modal => {
  const elements = {
    pickupPoint: {
      wrapper: modal.querySelector(".pickup-points-wrapper"),
    },
    routings: {
      wrapper: modal.querySelector(".routings-wrapper"),
    },
    system: {
      wrapper: modal.querySelector(".system-routings-wrapper"),
    },
    returnRoutings: {
      wrapper: modal.querySelector(".return-routings-wrapper"),
    },
    microdepots: {
      wrapper: modal.querySelector(".microdepots-wrapper"),
    },
    ppRoutes: {
      wrapper: modal.querySelector(".pickup-point-routes-wrapper"),
    },
    ecRoutes: {
      wrapper: modal.querySelector(".external-carrier-routes-wrapper"),
    },
    hcRoutes: {
      wrapper: modal.querySelector(".hd-cz-routes-wrapper"),
    }
  };

  for (const [key, value] of Object.entries(elements)) {
    value.select = value.wrapper?.querySelector("select");
  }

  return elements;
};

const setSelectMultiple = (select, group, isMultiple) => {
  select.multiple = isMultiple;

  if (isMultiple && group === "at_chute") {
    select.dataset.maxItemsCount = 4;
  } else if (isMultiple && group === "on_ground") {
    select.dataset.maxItemsCount = 7;
  } else {
    delete select.dataset.maxItemsCount;
  }
};

const initializeRoutingTypeChoices = (group, elements, routingType, routes) => {
  switch (routingType) {
    case ROUTING_TYPES.AGGREGATED_LINE:
    case ROUTING_TYPES.LINE:
      createRoutingChoices(
        group,
        elements.routings,
        routingType === ROUTING_TYPES.AGGREGATED_LINE,
        routes
      );
      break;
    case ROUTING_TYPES.AGGREGATED_RETURN_CLIENT:
    case ROUTING_TYPES.RETURN_CLIENT:
      createRoutingChoices(
        group,
        elements.returnRoutings,
        routingType === ROUTING_TYPES.AGGREGATED_RETURN_CLIENT,
        routes
      );
      break;
    case ROUTING_TYPES.AGGREGATED_EXPEDITION:
    case ROUTING_TYPES.EXPEDITION:
      createRoutingChoices(
        group,
        elements.pickupPoint,
        routingType === ROUTING_TYPES.AGGREGATED_EXPEDITION,
        routes
      );
      break;
    case ROUTING_TYPES.AGGREGATED_MICRODEPOT_EXPEDITION:
    case ROUTING_TYPES.MICRODEPOT_EXPEDITION:
      createRoutingChoices(
        group,
        elements.microdepots,
        routingType === ROUTING_TYPES.AGGREGATED_MICRODEPOT_EXPEDITION,
        routes
      );
      break;
    case ROUTING_TYPES.AGGREGATED_PICKUP_POINT_ROUTE:
    case ROUTING_TYPES.PICKUP_POINT_ROUTE:
      createRoutingChoices(
        group,
        elements.ppRoutes,
        routingType === ROUTING_TYPES.AGGREGATED_PICKUP_POINT_ROUTE,
        routes
      );
      break;
    case ROUTING_TYPES.AGGREGATED_EXTERNAL_CARRIER_ROUTE:
    case ROUTING_TYPES.EXTERNAL_CARRIER_ROUTE:
      createRoutingChoices(
        group,
        elements.ecRoutes,
        routingType === ROUTING_TYPES.AGGREGATED_EXTERNAL_CARRIER_ROUTE,
        routes
      );
      break;
    case ROUTING_TYPES.AGGREGATED_HD_CZ_ROUTE:
    case ROUTING_TYPES.HD_CZ_ROUTE:
      createRoutingChoices(
        group,
        elements.hcRoutes,
        routingType === ROUTING_TYPES.AGGREGATED_HD_CZ_ROUTE,
        routes
      );
      break;
    case ROUTING_TYPES.AGGREGATED_SYSTEM:
      createRoutingChoices(group, elements.system, true, routes, true);
      break;
    default:
      break;
  }
};

const createRoutingChoices = (
  group,
  element,
  isAggregatedRouting,
  routes,
  systemRoutes = false
) => {
  if (!element) return;

  const { select, wrapper } = element;
  wrapper.classList.remove(DISPLAY_NONE);
  setSelectMultiple(select, group, isAggregatedRouting);

  const choices = addChoices(new Choices(select, choicesOptions(select)));

  if (routes) {
    const choiceList = choices.config.choices;

    const activeRoutes = choiceList.filter(choice => {
      const choiceValue = systemRoutes ? choice.value : +choice.value;

      return routes.includes(choiceValue);
    });

    choices.setValue(activeRoutes);
  }

  return choices;
};

const fetchChuteRoutingConfig = (modal, chuteConfigId, typeChoices) => {
  const path = modal
    .getAttribute("data-chute-config-path")
    .replace(encodeURIComponent("%%chute_config_id%%"), chuteConfigId);

  Api.getJSON(path)
    .then(response => response.json())
    .then(data => initChuteConfig(data, typeChoices, modal));
};

const removeChuteFromSelect = (select, chuteId) => {
  for (let i = 0; i < select.length; i++) {
    if (select.options[i].value === chuteId) {
      select.remove(i);
    }
  }

  addChoices(new Choices(select, choicesOptions(select)));
};

const addChuteToSelect = (select, chuteId, chuteCode) => {
  const option = document.createElement("option");
  option.value = chuteId;
  option.text = chuteCode;

  select.add(option);
};

const hideSelects = elements => {
  for (const [key, value] of Object.entries(elements)) {
    destroyChoices(value.select);
    value.wrapper?.classList.add(DISPLAY_NONE);
  }
};
